import { Button, ButtonTheme } from "@wolfiesports/srm-component";
import { FormattedMessage } from "react-intl";
import { SubscriptionPlan } from "../../../models/SubscriptionModel";
import { PlanItem } from "../../../pages/Premium/PlanItem/PlanItem";
import "../styles.scss";
export type PaymentMethod = "paypal" | "stripe" | "mpesa";
const paypalLogo = require("../../../assets/Premium/paypal.png");
const cardsLogo = require("../../../assets/Premium/cards.png");

type PremiumModalPaymentMethodProps = {
  setSelectedPaymentMethod: (method: PaymentMethod) => void;
  selectedPlan: SubscriptionPlan;
  handlePrevStep: () => void;
};

export const PremiumModalPaymentMethod = ({
  setSelectedPaymentMethod,
  selectedPlan,
  handlePrevStep,
}: PremiumModalPaymentMethodProps) => {
  return (
    <div className="payment-method-container">
      <h5>
        <FormattedMessage id={"webapp.premium.select-payment-method"} />
      </h5>
      <PlanItem
        plan={selectedPlan}
        isSubscription={!selectedPlan.oneTimePayment}
        isSelected
      />
      <div className="button-container">
        <Button
          theme={ButtonTheme.CUSTOM}
          color="#292D30"
          textColor="white"
          label={
            <div className="button-label-container paypal">
              <p>Paypal</p> <img src={paypalLogo} alt="paypal-img" />
            </div>
          }
          setClick={() => setSelectedPaymentMethod("paypal")}
        />
        <Button
          theme={ButtonTheme.CUSTOM}
          color="#292D30"
          textColor="white"
          label={
            <div className="button-label-container credit-card">
              <p>
                <FormattedMessage id="webapp.premium.credit-card" />
              </p>
              <img src={cardsLogo} alt="cards-img" />
            </div>
          }
          setClick={() => setSelectedPaymentMethod("stripe")}
        />
        <Button
          theme={ButtonTheme.CUSTOM}
          color="#292D30"
          textColor="white"
          label={"Mpesa"}
          setClick={() => setSelectedPaymentMethod("mpesa")}
        />

        <Button
          label={<FormattedMessage id="webapp.premium.go-back" />}
          setClick={handlePrevStep}
        />
      </div>
    </div>
  );
};
